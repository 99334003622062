import service from "../service.js";

//查询辅助工具信息
export const searchAuxilInfo = params => {
    return service({
        method: 'POST',
        url: '/auxiliaryInfo/search',
        params
    })
}

//查询辅助工具报废信息
export const searchScrapInfo = params => {
    return service({
        method: 'POST',
        url: '/auxiliaryInfo/searchScrap',
        params
    })
}

export const addAuxiliaryInfo = (data) => {
    return service({
        method: 'POST',
        url: '/auxiliaryInfo/addAuxiliaryInfo',
        data
    })
}

export const updateAuxiliaryInfo = (data) => {
    return service({
        method: 'POST',
        url: '/auxiliaryInfo/updateAuxiliaryInfo',
        data
    })
}

export const scrapAuxilInfo = (id) => {
    return service({
        method: 'GET',
        url: '/auxiliaryInfo/scrap',
        params: {
            id
        }
    })
}

export const queryAuxiliaryInfo = (id) => {
    return service({
        method: 'GET',
        url: '/auxiliaryInfo/queryAuxiliaryInfo',
        params: {
            id
        }
    })
}


export const getAuxiliaryInfo = (auxCode) => {
    return service({
        method: 'GET',
        url: '/auxiliaryInfo/getAuxiliaryInfo',
        params: {
            auxCode
        }
    })
}

export const getAuxilStatus = () => {
    return service({
      method: 'GET',
      url: '/param/getAuxilStatus',
    })
}




